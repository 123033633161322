import React from 'react'
import Background from './Background'
import assets from '../data'
import { ReactComponent as XFLBall } from '../assets/xfl-ball.svg'
import { ReactComponent as CloseButton } from '../assets/close.svg'
import './ShareScreen.scss'

export default class ShareScreen extends React.Component {
  componentDidMount = () => {
    window.dataLayer.push({
      'event': 'pageType',
      'pageType': `Share Page : ${this.props.team}`
    })
  }
  
  render = () => 
    <div className='share-screen'>
      <Background teamName={this.props.team}/>
      <div className="content">
        <h1>Save & Share</h1>
        <p>Share your photo on social with <span className='xfl-ball' style={{ color: assets.teams[this.props.team].color }}><XFLBall/></span>  to be featured!</p>
        <div className="photo_container">
          <img src={this.props.img} className="inner_photo" alt=""/>
        </div>
        <div className="sub-line">Press & hold the photo above to download</div>
        <img className="logo" src={assets.xflLogo} alt=""/>
        <CloseButton className="close" onClick={this.props.closeShare}/>
      </div>
    </div>
}