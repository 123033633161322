import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

import * as THREE from 'three'

const fbxLoader = new FBXLoader()
const gltfLoader = new GLTFLoader()
const textureLoader = new THREE.TextureLoader()

export default class AssetLoader {
  static loadCache = {
    models: {},
    textures: {},
  }
  static objectCache = {
    models: {},
    textures: {},
  }
  static load = assets => {
    let arr = []
    if (assets.models) {
      arr.push(AssetLoader.loadModels(assets.models))
    }
    if (assets.textures) {
      arr.push(AssetLoader.loadTextures(assets.textures))
    }
    return Promise.all(arr)
  }
  
  static loadModels = models => Promise.all(Object.keys(models).map(key => this.loadModel(key, models[key])))
  
  static loadModel = (key, path) => AssetLoader.loadCache.models[key] = AssetLoader.loadCache.models[key] || new Promise(resolve => {
    console.log(`Loading model ${key}: ${path}`)
    if (AssetLoader.objectCache.models[key]) {
      resolve()
    } else {
      if (path.endsWith('.glb') || path.endsWith('.gltf')) {
        gltfLoader.load(path, gltf => resolve(AssetLoader.objectCache.models[key] = gltf))
      } else if (path.endsWith('.fbx')) {
        fbxLoader.load(path, fbx => {
          resolve(AssetLoader.objectCache.models[key] = fbx)
        }, null, e => {
          console.error('FBXLoader Error', e)
        })
      }
    }
  })
  
  static getModel = key => AssetLoader.objectCache.models[key]
  
  static loadTextures = textures => Promise.all(Object.keys(textures).map(key => this.loadTexture(key, textures[key])))
  
  static loadTexture = (key, path) => AssetLoader.loadCache.textures[key] = AssetLoader.loadCache.textures[key] || new Promise(resolve => {
    console.log(`Loading Texture ${key}: ${path}`)
    if (AssetLoader.objectCache.textures[key]) {
      resolve()
    } else {
      textureLoader.load(
        path,
        texture => {
          resolve(AssetLoader.objectCache.textures[key] = texture)
        },
        undefined,
        (e) => {
          console.error('TextureLoader Error', e)
        }
      )
    }
  })

  static getTexture = key => AssetLoader.objectCache.textures[key]
}

