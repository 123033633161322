import React from 'react'
import './Team.scss'
import assets from '../data'
import { ReactComponent as ThisIs } from '../assets/this-is.svg'
import Background from './../components/Background'
import { isIOS, isMobile } from 'react-device-detect'

export default class Team extends React.Component {
  componentDidMount = () => {
    window.dataLayer.push({
      'event': 'pageType',
      'pageType': `Team Page : ${this.getTeamName()}` 
    })
  }

  onLogoClick = () => {
    if (!isIOS) {
      this.props.history.push(`/experience/${this.getTeamName()}`)
    }
  }

  renderMobile = () => {
    this.is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
    return <>
      <div className="center-content">
        <h1 ref={r => this.header = r}>
          <ThisIs className={`this-is ${this.getLineBreak() ? 'short-name' : ''}`} ref={r => this.styledTxt = r}/>
          { this.getLineBreak() &&
            `THE\u00a0${this.getTeamName()} GAME\u00a0BALL.`.split(' ').map(word => [<span>{word}</span>, ' '])
          }
          { !this.getLineBreak() &&
            `THE ${this.getTeamName()} GAME\u00a0BALL.`.split(' ').map(word => [<span>{word}</span>, ' '])
          }
        </h1>
        { isIOS && !this.is_uiwebview &&
          <a rel="ar" href={assets.teams[this.getTeamName()].usdz}>
            <img src={assets.teams[this.getTeamName()].logo} alt="" className="team-logo" style={{ maxWidth: `${assets.teams[this.getTeamName()].width}rem` }}/>
          </a>
        }
        { !isIOS && !this.is_uiwebview &&
          <img onClick={this.onLogoClick} src={assets.teams[this.getTeamName()].logo} alt="" className="team-logo" style={{ maxWidth: `${assets.teams[this.getTeamName()].width}rem` }}/>
        }
        { this.is_uiwebview &&
           <p className="warning" style={{ color: assets.teams[this.getTeamName()].logoColor }}>To view this experience<br/>open your mobile browser and<br/>visit xfl.com/AR</p>
        }
        <p className={`${this.is_uiwebview ? 'hide' : ''}`}><span>Tap the logo to put your</span><br/><span>team football in your hands.</span></p>
        <div className={`instructions ${this.is_uiwebview ? 'hide' : ''}`}>
          <span className="instructions-color" style={{ color: assets.teams[this.getTeamName()].logoColor }}>Pinch, zoom, drag, and post photos with</span> <span className="instructions-white">#XFLBall</span>
        </div>
        <img src={assets.xflLogo} alt="" className="league-logo" />
        <div className="linkContainer">
          <a href="https://www.xfl.com/en-US/terms-and-conditions" target={"_blank"}>TERMS & conditions</a>
          <a href="https://www.xfl.com/en-US/privacy-policy" target={"_blank"}>privacy policy</a>
        </div>
      </div>
    </> 
  }

  renderDesktop = () => {
    return <>
      <div className="center-content">
        <h1 className="desktop-header" ref={r => this.header = r}>
          <ThisIs className="this-is" ref={r => this.styledTxt = r}/>
          {`THE ${this.getTeamName()} GAME\u00a0BALL.`.split(' ').map(word => [<span>{word}</span>, ' '])}
        </h1>
        <p>This is a fully mobile experience. Please go to ar.xfl.com<br/>on your iPhone or Android device to view the ball.</p>
      </div>
      <div className="desktop-link-wrapper">
        <img src={assets.xflLogo} alt="" className="league-logo" />
        <div className="linkContainer">
          <a href="https://www.xfl.com/en-US/terms-and-conditions" target={"_blank"}>TERMS & conditions</a>
          <a href="https://www.xfl.com/en-US/privacy-policy" target={"_blank"}>privacy policy</a>
        </div>
      </div>
    </> 
  }
 
  getTeamName = () => this.props.match.params.team.toLowerCase()

  getLineBreak = () => {
    if (this.getTeamName() === 'battlehawks' || this.getTeamName() === 'renegades' || this.getTeamName() === 'roughnecks') {
      return false 
    } else {
      return true
    }
  }
 
  render = () =>
    <div className={`team-view ${this.getTeamName()} ${isMobile ? '' : 'desktop'}`}>
      <Background teamName={this.getTeamName()}/>
      { isMobile ? this.renderMobile() : this.renderDesktop() }
    </div>
}
