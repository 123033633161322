import React from 'react'
import Experience from './views/Experience'
import Team from './views/Team'
import Landing from './views/Landing'
import { isMobile } from 'react-device-detect'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.scss'

export default class App extends React.Component {
  constructor () {
    super()
    if (!isMobile) {
      document.querySelector('html').classList.add('desktop')
    }
  }
  render = () => 
    <div className="app">
      <Router>
        <Switch>
          <Route path="/experience/:team" component={Experience}/>
          <Route path="/team/:team" component={Team}/>
          <Route path="/" component={Landing}/>
        </Switch>
      </Router>
    </div>
}
