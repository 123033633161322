import React from 'react'
import Carousel from "nuka-carousel";
import './Landing.scss'
import assets from '../data'
import { ReactComponent as ThisIs } from '../assets/this-is.svg'
import Background from './../components/Background'
import { isMobile, isIOS } from 'react-device-detect'

export default class Landing extends React.Component {
  teams = Object.keys(assets.teams)
  state = {
    slideIndex: window.oldSlideIndex || 0
  };

  componentDidMount = () => {
    window.dataLayer.push({
      'event': 'pageType',
      'pageType': 'Landing Page' 
    })
  }

  onBtnClick = () => {
    window.oldSlideIndex = this.state.slideIndex
    this.props.history.push(`/experience/${this.teams[this.state.slideIndex]}`)
  }

  updateCarouselSlide = (direction) => {
    console.log(direction, this.state.slideIndex)
    if(direction === 'right') {
      this.setState({
        slideIndex: this.state.slideIndex + 1
      })
    } else if (direction === 'left') {
      this.setState({
        slideIndex: this.state.slideIndex - 1
      })
    }
  }

  renderMobile = () => {
    this.is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
   return <div className="center-content">
      <h1 ref={r => this.header = r}>
        <ThisIs className="this-is" ref={r => this.styledTxt = r}/>
        <span>EIGHT</span><br/>
        <span>XFL GAME BALLS.</span>
      </h1>
      <div className={`${this.is_uiwebview ? 'hide' : ''}`}>
        <Carousel withoutControls={true} wrapAround={true} slideIndex={this.state.slideIndex} afterSlide={slideIndex => this.setState({ slideIndex })}>
        {this.teams.map((index) => (
          <React.Fragment key={index}>
          { isIOS &&
            <a rel="ar" href={assets.teams[index].usdz}>
              <img src={assets.teams[index].logo} alt="" className="team-logo"/>
            </a>
          }
          { !isIOS &&
            <img src={assets.teams[index].logo} onClick={this.onBtnClick} alt="" className="team-logo"/>
          }
          {/* <p className="team-name-carousel"><span>{assets.teams[index].name}</span></p> */}
          </React.Fragment>
        ))}
        </Carousel>
      </div>
      <div onClick={() => this.updateCarouselSlide('right')} className={`arrow right-arrow ${this.is_uiwebview ? 'hide' : ''}`}></div>
      <div onClick={() => this.updateCarouselSlide('left')} className={`arrow left-arrow ${this.is_uiwebview ? 'hide' : ''}`}></div>
      <p className={`${this.is_uiwebview ? 'hide' : ''}`}><span>Swipe to Select team and<br/>tap logo to see new Ball</span></p>
      <div className={`instructions ${this.is_uiwebview ? 'hide' : ''}`}>
        <span className="instructions-color">Pinch, zoom, drag, and post photos with</span> <span className="instructions-white">#XFLBall</span>
      </div>
      <p className={`warning ${this.is_uiwebview ? '' : 'hide'}`}>To view this experience<br/>open your mobile browser and visit xfl.com/AR</p>
      <img src={assets.xflLogo} alt="" className="league-logo" />
      <div className="linkContainer">
        <a href="https://www.xfl.com/en-US/terms-and-conditions" target={"_blank"}>TERMS & conditions</a>
        <a href="https://www.xfl.com/en-US/privacy-policy" target={"_blank"}>privacy policy</a>
      </div>
    </div>
  }

  renderDesktop = () => {
    return <>
      <div className="center-content">
        <h1 ref={r => this.header = r}>
          <ThisIs className="this-is" ref={r => this.styledTxt = r}/>
          <span>THE NEW XFL BALL.</span>
        </h1>
        <p>This is a fully mobile experience. Please go to ar.xfl.com<br/>on your iPhone or Android device to view the ball.</p>
      </div>
      <div className="desktop-link-wrapper">
        <img src={assets.xflLogo} alt="" className="league-logo" />
        <div className="linkContainer">
          <a href="https://www.xfl.com/en-US/terms-and-conditions" target={"_blank"}>TERMS & conditions</a>
          <a href="https://www.xfl.com/en-US/privacy-policy" target={"_blank"}>privacy policy</a>
        </div>
      </div>
    </>
  }
  
  render = () =>
    <div className={`landing-page ${isMobile ? '' : 'desktop'}`}>
      <Background/>
      { isMobile ? this.renderMobile() : this.renderDesktop() }
    </div>
}
