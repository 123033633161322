import React from 'react'
import Webcam from 'react-webcam'
import Experience from '../scripts/Experience'
import LoadingScreen from '../components/LoadingScreen'
import ShareScreen from '../components/ShareScreen'
import * as Hammer from 'hammerjs'
import { ReactComponent as CloseButton } from '../assets/close.svg'
import { ReactComponent as SnapshotButton } from '../assets/snapshot.svg'
import './Experience.scss'

export default class ExperienceComponent extends React.Component {
  state = { loading: true }

  componentDidMount = () => {
    window.dataLayer.push({
      'event': 'pageType',
      'pageType': `Web AR Experience Page : ${this.getTeamName()}` 
    })

    this.loadPromise = new Promise(resolve => this.loadResolver = resolve)
    this.cameraLoadPromise = new Promise(resolve => this.cameraLoadResolver = resolve)
    this.experience = new Experience()

    this.experience.init(this.canvas, this.getTeamName()).then(() => {
      window.addEventListener('resize', this.onResize)
      window.addEventListener('orientationchange', this.onResize)
      this.initHammer()
      this.loadResolver()
    })

    this.cameraContainer.querySelector('video').addEventListener('play', this.onResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('orientationchange', this.onResize)
    this.experience.unload()
  }

  getTeamName = () => this.props.match.params.team.toLowerCase()

  getHammerCoordinates = e => {
    const bounds = this.canvas.getBoundingClientRect()
    return {
      x: (e.center.x - bounds.left) / bounds.width * 2 - 1,
      y: 1 - (e.center.y - bounds.top) / bounds.height * 2,
      scale: e.scale,
      rotation: e.rotation * Math.PI / 180,
      pointerPositions: e.pointers.map(p => ({
        x: (p.screenX - bounds.left) / bounds.width * 2 - 1,
        y: 1 - (p.screenY - bounds.top) / bounds.height * 2
      }))
    }
  }

  initHammer = () => {
    this.hammer = new Hammer(this.canvas)
    this.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL, threshold: 0, pointers: 0 })
    this.hammer.on('pan', e => this.experience.onPan(this.getHammerCoordinates(e)))
    this.hammer.on('panend', e => this.experience.onRelease(e))
  }

  onResize = () => {
    const video = this.cameraContainer.querySelector('video')
    const bounds = this.cameraContainer.getBoundingClientRect()

    const videoRatio = video.videoWidth / video.videoHeight
    const containerRatio = bounds.width / bounds.height

    let width, height

    if (containerRatio > videoRatio) {
      width = bounds.width
      height = bounds.width / videoRatio
    } else {
      width = bounds.height * videoRatio
      height = bounds.height
    }

    video.style.width = `${width}px`
    video.style.height = `${height}px`

    this.setSize(width, height)
  }

  setSize = (width, height) => {
    this.loadPromise.then(() => {
      const b = this.cameraContainer.getBoundingClientRect()
      const a = {
        width,
        height,
        left: (b.width - width) / 2,
        top: (b.height - height) / 2
      }
      this.experience.setSize(width, height, a)
      if (!this.loaded) {
        this.loaded = true
        this.cameraLoadResolver()
        this.setState({ loading: false })
      }
    })
  }

  getScreenshot = () => new Promise(resolve => {
    const composition = document.createElement('canvas')
    const context = composition.getContext('2d')
    const video = this.cameraContainer.querySelector('video')
    const bounds = this.cameraContainer.getBoundingClientRect()
    const canvasBounds = this.canvas.getBoundingClientRect()
    const videoBounds = video.getBoundingClientRect()

    composition.width = bounds.width * window.devicePixelRatio
    composition.height = bounds.height * window.devicePixelRatio

    context.drawImage(
      video,
      videoBounds.left * window.devicePixelRatio,
      videoBounds.top * window.devicePixelRatio,
      this.canvas.width,
      this.canvas.height
    )

    context.drawImage(
      this.canvas,
      canvasBounds.left * window.devicePixelRatio,
      canvasBounds.top * window.devicePixelRatio,
      this.canvas.width,
      this.canvas.height
    )

    resolve(composition.toDataURL('image/jpeg'))
  })

  takeSnapshot = () => {
    this.getScreenshot().then(snapshot => this.setState({ snapshot }))
  }

  closeShare = () => {
    this.setState({ snapshot: null })
  }

  render = () =>
    <div className="experience">
      { this.state.loading && <LoadingScreen/> }
      { this.state.snapshot && <ShareScreen closeShare={this.closeShare} img={this.state.snapshot} team={this.getTeamName()} />}
      <div ref={r => this.cameraContainer = r} className="camera-container">
        <Webcam ref={r => this.camera = r} audio={false} screenshotFormat={'image/jpeg'} onUserMedia={this.onUserMedia} videoConstraints={{ facingMode: { ideal: 'environment' }, height: 1280 }} />
        <canvas ref={r => this.canvas = r}/>
      </div>
      <div className="ui">
        <SnapshotButton className='snapshot-button' onClick={this.takeSnapshot}/>
        <CloseButton className='close-button' onClick={() => this.props.history.push('/')}/>
      </div>
    </div>
}