import React from 'react'
import Background from '../components/Background'
import './LoadingScreen.scss'

export default class LoadingScreen extends React.Component {
  componentDidMount = () => {
  }
  render = () =>
    <div className="loading-screen">
      <Background/>
      <div className="copy">Loading...</div>
    </div>
}