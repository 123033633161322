import React from 'react'
import './Background.scss'
import assets from '../data'
import { isMobile } from 'react-device-detect'

export default class Background extends React.Component {
  componentDidMount = () => {
  }

  getTeamObject = () => {
    if (this.props.teamName) {
      return assets.teams[this.props.teamName]
    } else {
      return assets.landing
    }
  }
  
  render = () =>
    <div className={`background ${this.props.teamName || 'landing'} ${isMobile ? '' : 'desktop'}`}>
      <div className="textureBg"></div>
      <div className="team-name one">{this.getTeamObject().name}</div>
      <div className="team-name two">{this.getTeamObject().name}</div>
      <div className="team-name three">{this.getTeamObject().name}</div>
    </div>
}