import React from 'react'

export default {
  xflLogo: require('./assets/xfl-logo.svg'),
  landing: {
    name: 'This is the new XFL ball.',
  },
  teams: {
    battlehawks: {
      name: 'ST. LOUIS BATTLEHAWKS',
      width: '55.65',
      logo: require('./assets/battlehawks-logo.svg'),
      bg: require('./assets/battlehawks-bg.jpg'),
      texture: require('./assets/textures/Football_Color_battlehawks.png'),
      usdz: require('./assets/usdz/battlehawks.usdz'),
      color: 'rgba(0, 78, 255, 0.5)',
      logoColor: 'rgb(145, 145, 145)',
    },
    defenders: {
      name: 'D.C. DEFENDERS',
      width: '45.36',
      logo: require('./assets/defenders-logo.svg'),
      bg: require('./assets/defenders-bg.jpg'),
      texture: require('./assets/textures/Football_Color_defenders.png'),
      usdz: require('./assets/usdz/defenders.usdz'),
      color: 'rgba(248, 0, 28, 0.5)',
      logoColor: 'rgb(180, 20, 40)',
    },
    dragons: {
      name: 'SEATTLE DRAGONS',
      width: '55.73',
      logo: require('./assets/dragons-logo.svg'),
      bg: require('./assets/dragons-bg.jpg'),
      texture: require('./assets/textures/Football_Color_dragons.png'),
      usdz: require('./assets/usdz/dragons.usdz'),
      color: 'rgba(233, 60, 22, 0.7)',
      logoColor: 'rgb(233, 60, 22)',
    },
    guardians: {
      name: 'NEW YORK GUARDIANS',
      width: '62.16',
      logo: require('./assets/guardians-logo.svg'),
      bg: require('./assets/guardians-bg.jpg'),
      texture: require('./assets/textures/Football_Color_guardians.png'),
      usdz: require('./assets/usdz/guardians.usdz'),
      color: 'rgba(248, 0, 28, 0.5)',
      logoColor: 'rgb(180, 20, 40)',
    },
    renegades: {
      name: 'DALLAS RENEGADES',
      width: '63',
      logo: require('./assets/renegades-logo.svg'),
      bg: require('./assets/renegades-bg.jpg'),
      texture: require('./assets/textures/Football_Color_renegades.png'),
      usdz: require('./assets/usdz/renegades.usdz'),
      color: 'rgba(232, 3, 3, 0.8)',
      logoColor: 'rgb(95, 155, 205)',
    },
    roughnecks: {
      name: 'HOUSTON ROUGHNECKS',
      width: '46.65',
      logo: require('./assets/roughnecks-logo.svg'),
      bg: require('./assets/roughnecks-bg.jpg'),
      texture: require('./assets/textures/Football_Color_roughnecks.png'),
      usdz: require('./assets/usdz/roughnecks.usdz'),
      color: 'rgba(248, 0, 28, 0.5)',
      logoColor: 'rgb(180, 20, 40)',
    },
    vipers: {
      name: 'TAMPA BAY VIPERS',
      width: '58.1',
      logo: require('./assets/vipers-logo.svg'),
      bg: require('./assets/vipers-bg.jpg'),
      texture: require('./assets/textures/Football_Color_vipers.png'),
      usdz: require('./assets/usdz/vipers.usdz'),
      color: 'rgba(160, 210, 70, 0.5)',
      logoColor: 'rgb(251, 168, 24)',
    },
    wildcats: {
      name: 'LOS ANGELES WILDCATS',
      width: '44.8',
      logo: require('./assets/wildcats-logo.svg'),
      bg: require('./assets/wildcats-bg.jpg'),
      texture: require('./assets/textures/Football_Color_wildcats.png'),
      usdz: require('./assets/usdz/wildcats.usdz'),
      color: 'rgba(255, 138, 0, 0.7)',
      logoColor: 'rgb(236, 118, 22)',
    }
  }
}