import * as THREE from 'three'
import AssetLoader from './AssetLoader'
import Data from '../data'

const minScale = 0.1
const defaultScale = 0.15
const maxScale = 0.25

export default class {
  init = (canvas, team = 'vipers') => new Promise(resolve => {
    this.resolver = resolve
    this.team = team
    AssetLoader.load({
      models: {
        football: require('../assets/Football.fbx')
      },
      textures: {
        ao: require('../assets/Football_AO.png'),
        [`color${this.team}`]: Data.teams[team].texture,
        metallic: require('../assets/Football_Metallic.png'),
        normal: require('../assets/Football_Normal.png'),
        roughness: require('../assets/Football_Roughness.png'),
      }
    }).then(() => {
      this.initRenderer(canvas)
      this.initCamera()
      this.initScene()
      this.initRaycaster()
      this.update()
    })
  })

  unload = () => {
    this.football.geometry.dispose()
    this.football.material.dispose()
    this.unmounted = true
  }

  initCamera = () => {
    this.camera = new THREE.PerspectiveCamera(70, 50, 0.01, 1000)
    this.camera.position.z = 10
  }

  initScene = () => {
    this.scene = new THREE.Scene()
    this.initFootball()
    this.initLights()
    this.initHitPlane()
    this.initTestBoundaries()
  }

  initFootball = () => {
    this.football = AssetLoader.getModel('football').clone().children[0]
    this.football.material = new THREE.MeshPhysicalMaterial({
      map: AssetLoader.getTexture(`color${this.team}`),
      aoMap: AssetLoader.getTexture('ao'),
      metalnessMap: AssetLoader.getTexture('metallic'),
      normalMap: AssetLoader.getTexture('normal'),
      roughnessMap: AssetLoader.getTexture('roughness'),
      roughness: 0.7
    })
    this.football.scale.set(defaultScale, defaultScale, defaultScale)
    this.football.rotateX(0.5)
    this.scene.add(this.football)
  }

  initTestBoundaries = () => {
    this.boundaries = ['up', 'left', 'down', 'right'].reduce((a, key, i) => {
      const geo = new THREE.Geometry()
      geo.vertices.push(
        new THREE.Vector3(-10, 0, 0),
        new THREE.Vector3(10, 0, 0)
      )
      a[key] = new THREE.Line(
        geo,
        new THREE.LineBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0 })
      )
      a[key].rotateZ(i * Math.PI / 2)
      this.scene.add(a[key])
      return a
    }, {})
  }

  initHitPlane = () => {
    this.plane = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(100, 100, 100, 100),
      new THREE.MeshPhongMaterial({ color: 0xaaaaaa, transparent: true, opacity: 0 })
    )
    this.scene.add(this.plane)
  }

  initLights = () => {
    const light1 = new THREE.AmbientLight(0xffffff, 0.3)
    const light2 = new THREE.DirectionalLight(0xffffff, 0.4)
    const light3 = new THREE.DirectionalLight(0xffffff, 0.4)
    const light4 = new THREE.DirectionalLight(0xffffff, 0.4)
    const light5 = new THREE.DirectionalLight(0xffffff, 0.4)

    light2.position.set(8, 0, 2)
    light3.position.set(-8, 1, 2)
    light4.position.set(0, -2, 4)
    light5.position.set(0, 8, 2)

    light2.target = this.football
    light3.target = this.football
    light4.target = this.football
    light5.target = this.football

    this.scene.add(light1)
    this.scene.add(light2)
    this.scene.add(light3)
    this.scene.add(light4)
    this.scene.add(light5)
  }

  initRenderer = canvas => {
    this.canvas = canvas
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true, canvas })
    this.renderer.setPixelRatio(window.devicePixelRatio)
  }

  initRaycaster = () => {
    this.raycaster = new THREE.Raycaster()
    // this.marker = new THREE.Mesh(
    //   new THREE.SphereBufferGeometry(0.25, 8, 8),
    //   new THREE.MeshBasicMaterial({ color: 0xff0000 })
    // )
    // this.scene.add(this.marker)
  }

  setSize = (width, height, bounds) => {
    this.renderer.setSize(width, height)
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.updateBoundaries(bounds)
    this.checkFootballPosition()
  }

  updateBoundaries = bounds => {
    // up
    this.raycaster.setFromCamera({
      x: 0,
      y: (bounds.height + bounds.top) / bounds.height * 2 - 1
    }, this.camera)
    this.raycaster.intersectObjects([this.plane]).forEach(child => this.boundaries.up.position.copy(child.point).add(new THREE.Vector3(0, -1, 0)))

    // down
    this.raycaster.setFromCamera({
      x: 0,
      y: -bounds.top / bounds.height * 2 - 1
    }, this.camera)
    this.raycaster.intersectObjects([this.plane]).forEach(child => this.boundaries.down.position.copy(child.point).add(new THREE.Vector3(0, 1, 0)))

    // left
    this.raycaster.setFromCamera({
      x: -bounds.left / bounds.width * 2 - 1,
      y: 0
    }, this.camera)
    this.raycaster.intersectObjects([this.plane]).forEach(child => this.boundaries.left.position.copy(child.point).add(new THREE.Vector3(1, 0, 0)))

    // right
    this.raycaster.setFromCamera({
      x: (bounds.width + bounds.left) / bounds.width * 2 - 1,
      y: 0
    }, this.camera)
    this.raycaster.intersectObjects([this.plane]).forEach(child => this.boundaries.right.position.copy(child.point).add(new THREE.Vector3(-1, 0, 0)))
  }

  getAngleDifference = (a, b) => Math.atan2(Math.sin(b - a), Math.cos(b - a))

  checkFootballPosition = () => {
    const hScale = this.football.scale.x / 1
    const vScale = this.football.scale.y / 1.25
    if (this.football.position.x < this.boundaries.left.position.x + hScale) {
      this.football.position.x = this.boundaries.left.position.x + hScale
    }
    if (this.football.position.y > this.boundaries.up.position.y - vScale) {
      this.football.position.y = this.boundaries.up.position.y - vScale
    }
    if (this.football.position.x > this.boundaries.right.position.x - hScale) {
      this.football.position.x = this.boundaries.right.position.x - hScale
    }
    if (this.football.position.y < this.boundaries.down.position.y + vScale) {
      this.football.position.y = this.boundaries.down.position.y + vScale
    }
  }

  onPan = data => {
    if (!this.dragging) {
      this.initialPointerPosition = data.pointerPositions[0]
      this.raycaster.setFromCamera(this.initialPointerPosition, this.camera)
      this.raycaster.intersectObjects([this.plane]).forEach(child => {
        this.planeHitPosition = child.point
        // this.marker.position.copy(this.planeHitPosition)
      })
      // if (this.raycaster.intersectObjects([this.football]).length) {
        this.dragging = true
        this.initialFootballPosition = this.football.position.clone()
        if (!this.initialIntersection) {
          this.initialIntersection = this.planeHitPosition
        }
      // }
    } else {
      const closestPointer = data.pointerPositions.sort((a, b) => {
        const distance = ((a.x - this.initialPointerPosition.x) ** 2 + (a.y - this.initialPointerPosition.y) ** 2) ** 0.5 -
        ((b.x - this.initialPointerPosition.x) ** 2 + (b.y - this.initialPointerPosition.y) ** 2) ** 0.5
        return distance
      })[0]
      this.initialPointerPosition = closestPointer
      this.raycaster.setFromCamera(closestPointer, this.camera)
      this.raycaster.intersectObjects([this.plane]).forEach(child => {
        this.planeHitPosition = child.point
        // this.marker.position.copy(this.planeHitPosition)
      })
    }
    if (!this.multiTouching && data.pointerPositions.length >= 2) {
      this.multiTouching = true
      this.initialInputRotation = data.rotation
      this.initialRotation = this.football.rotation.y
      this.initialFootballScale = this.football.scale.clone()
    }
    if (this.dragging) {
      const difference = this.planeHitPosition.clone().sub(this.initialIntersection)
      this.football.position.copy(this.initialFootballPosition.clone().add(difference))
      this.checkFootballPosition()
      if (data.pointerPositions.length >= 2) {
        const newScale = this.initialFootballScale.clone().multiplyScalar(data.scale)
        if (newScale.x >= maxScale) {
          newScale.set(maxScale, maxScale, maxScale)
        }
        if (newScale.x <= minScale) {
          newScale.set(minScale, minScale, minScale)
        }
        this.football.scale.copy(newScale)

        const angleDifference = this.getAngleDifference(data.rotation, this.initialInputRotation) * 2
        this.football.rotateZ(angleDifference)
        this.initialInputRotation = data.rotation
      }
    }
  }

  onRelease = data => {
    this.initialIntersection = null
    this.initialFootballPosition = null
    this.dragging = null
    this.planeHitPosition = null
    if (data.pointers.length <= 2) {
      this.multiTouching = false
      this.initialInputRotation = null
      this.initialRotation = null
    }
  }

  update = () => {
    if (!this.unmounted) {
      requestAnimationFrame(this.update)
      this.renderer.render(this.scene, this.camera)
      if (!this.firstRender) {
        this.firstRender = true
        this.resolver()
      }
    }
  }
}
